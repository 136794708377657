// 配置管理模块
import axios from 'axios'
import QS from 'qs'

// 配置列表分页
export const configPage = params => {
    return axios.post(`/api/config/page`, QS.stringify(params)).then(res => res.data)
}

// 配置列表 新增或编辑
export const configAddOnSet = params => {
    return axios.post(`/api/config/addOnSet`, QS.stringify(params)).then(res => res.data)
}

// 配置列表 删除
export const configDel = params => {
    return axios.post(`/api/config/del`, QS.stringify(params)).then(res => res.data)
}

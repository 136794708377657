<template>
  <div class="container">
    <!-- 卡片 -->
    <el-card class="box-card">
      <!-- 添加按钮 -->
      <el-button type="primary" size="small" @click="teacherShow = true">添加课程分类</el-button>
      <!-- 表格 -->
      <el-table :data="tableData" border stripe style="width: 100%" v-loading="loading">
        <!-- <el-table-column align="center" type="index" label="序号" width="100"></el-table-column> -->
        <el-table-column align="center" label="id" prop="id" width="150"></el-table-column>
        <el-table-column align="center" label="名称" prop="keyName"></el-table-column>
        <el-table-column align="center" label="编码" prop="keyCode"></el-table-column>
        <el-table-column align="center" label="值" prop="keyValue"></el-table-column>
        <el-table-column align="center" width="150" label="状态">
          <template slot-scope="scope">
            <el-tag :type="scope.row.status == 0 ? 'success' : 'danger'" disable-transitions>
              {{ scope.row.status == 0 ? '启用' : '停用' }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="250">
          <template slot-scope="scope">
            <el-button type="primary" @click="handlerEdit(scope.row)" icon="el-icon-edit" size="mini">编辑</el-button>
            <el-button type="danger" @click="handlerRemove(scope.row)" icon="el-icon-delete" size="mini">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                     :current-page="query.current"
                     :page-sizes="[5, 10, 20, 30]"
                     :page-size="query.size"
                     layout="total, sizes, prev, pager, next, jumper"
                     :total="total">
      </el-pagination>
    </el-card>
    <!-- 添加编辑弹出层 -->
    <el-dialog :title="form.id ? '编辑配置列表' : '添加配置列表'" :visible.sync="teacherShow" width="40%" @close="cancel">
      <el-form :model="form" ref="form" label-width="70px" class="demo-ruleForm" :rules="rules">
        <el-form-item label="名称:" prop="keyName">
          <el-input @focus="clearValidate('keyName')" v-model="form.keyName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="编码:" prop="keyCode">
          <el-input @focus="clearValidate('keyCode')" v-model="form.keyCode" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="值:" prop="keyValue">
          <el-input type="number" @focus="clearValidate('keyValue')" v-model="form.keyValue"
                    autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="状态:">
          <el-switch v-model="switchStatus" active-text="启用" inactive-text="停用"></el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="cancel">取 消</el-button>
        <el-button size="mini" type="primary" @click="resetForm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {configPage, configAddOnSet, configDel} from '../../apis/config'
import {config} from "@/utils/rules"

export default {
  data() {
    return {
      teacherShow: false, // 弹框线上状态
      loading: false,
      tableData: [],
      query: {
        current: 1,
        size: 10,
      },
      rules: config.rules, // 验证规则
      total: null,
      switchStatus: true, // 开启状态
      form: {
        keyCode: '',// 编码
        keyName: '', // 名称
        keyValue: '',// 值
        status: 0, // 状态
      },
    }
  },
  created() {
    this.getData()
  },
  methods: {
    async getData() {
      this.loading = true
      const res = await configPage(this.query)
      const {records, total, current, size} = res.data
      this.tableData = records
      this.total = total
      this.query.current = current
      this.query.size = size
      setTimeout(() => {
        this.loading = false
      }, 50)
    },

    // 编辑
    handlerEdit(item) {
      this.teacherShow = true
      const newObj = {...item}
      this.form = newObj
      this.switchStatus = item.status == 0 ? true : false
    },

    // 删除
    async handlerRemove(item) {
      this.$confirm('此操作将永久删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        await configDel({id: item.id})
        this.query.current = 1
        this.$message({message: '删除成功', type: "success"});
        this.getData()
      }).catch(err => {
      })
    },

    handleSizeChange(size) {
      this.query.size = size
      this.getData()
    },

    handleCurrentChange(page) {
      this.query.current = page
      this.getData()
    },

    // 取消弹出层
    cancel() {
      this.teacherShow = false
      this.switchStatus = true
      this.clearCen()
    },

    // 确定
    resetForm() {
      this.$refs.form.validate(async val => {
        if (!val) return
        this.form.status = this.switchStatus ? 0 : 1 // 是否禁用
        await configAddOnSet(this.form)
        this.$message({message: this.form.id ? '编辑成功' : '添加成功', type: "success"});
        this.teacherShow = false
        this.getData()
        this.clearCen()
      })
    },

    // 获取焦点对表单重新校验
    clearValidate(prop) {
      this.$refs.form.clearValidate(prop);
    },

    clearCen() {
      this.$refs.form.resetFields(); // 重置表单
      Object.keys(this.form).map((key) => (this.form[key] = ""));
      delete this.form.id // 删除对象里面的id
    },
  }
}
</script>

<style lang="less" scoped>

</style>